import Vue from 'vue';
import Component from 'vue-class-component';

export interface ValidationIntervace {
    valid: boolean | null;
    message: string;
}

export type ValidatorFeedback<T> = () => T;

export interface ValidatorOption {
    feedback: () => string;
    value: any;
    name: string;
    attribute: string;
}

import filter, { CurrencyFilterInterface } from "./Filters";
@Component({
    filters: {
        ...filter, ...{
            currency: (price: any, decimal = false, unit = true) => {
                if(isNaN(price)) price = 0
                let currency = Number(price).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' });
                currency = decimal ? currency.replace(new RegExp(/,[0-9]+$/), '') : currency;
                return unit ? currency : currency.slice(3);
            }
        }
    }
})
export class Validator extends Vue {
    currency!: CurrencyFilterInterface | any;

    public errBag: any = {};

    public validate(
        rule: string | string[],
        value: any,
        option: ValidatorOption | any | null | ValidatorFeedback<string> = null
    ): ValidationIntervace {

        if (rule instanceof Array) {
            for (let i = 0; i < rule.length; i++) {
                const element = rule[i];
                let check = this.check(rule, value, option);
                if (!check.valid) return check;
            }
        } else return this.check(rule, value, option);
    }

    private check(
        rule: string | string[],
        value: any,
        option: ValidatorOption | any | null | ValidatorFeedback<string> = null
    ) {
        let result: ValidationIntervace = {
            valid: null,
            message: ''
        };

        if (rule.indexOf('required') >= 0) return { ...result, ...{ valid: typeof value !== 'undefined' && value !== null && value !== 0 && value !== '' } };
        else if (rule.indexOf('nullable') >= 0 || !value) return result;

        if (rule.indexOf('email') >= 0) { result = this.validateEmail(value); }
        else if (rule.indexOf('min') >= 0) { result = this.validateMin(value, (typeof option === 'number' ? option : option?.value) || Number(rule.slice(4, rule.length))); }
        else if (rule.indexOf('same-as') >= 0) { result = this.validationSameAs(value, option.value, option.attribute || option.name); }
        else if (rule.indexOf('numeric') >= 0) {
            let is_valid = !isNaN(value);
            result = {
                message: is_valid ? "" : this.$t('validation.numeric').toString(),
                valid: is_valid
            };
        } else console.warn(`rule ${rule} does not exist`);

        if (typeof option?.feedback === 'function') option?.feedback(result.message);
        return result;
    }

    public validateEmail(v: string): ValidationIntervace {
        const reg = new RegExp(/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g);
        let valid = reg.test(v);
        let message = valid ? '' : (v.length >= 6 ? this.$t(`validation.email-invalid`).toString() : '');
        return {
            valid,
            message
        };
    }

    /**
     * validateMin
     */
    public validateMin(v: any, min: number): ValidationIntervace {
        let valid = String(v).length >= min;
        return {
            valid,
            message: valid ? '' : this.$t(`validation.min-invalid`, { min }).toString()
        };
    }

    /**
     * formatCurrency
     */
    public formatCurrency(price: any, removeUnit = false) {
        return Number(price).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).slice(removeUnit ? 2 : undefined);
    }

    /**
     * sameAs
     */
    public validationSameAs(value: any, otherValue: any, attribute: string) {
        let valid = value === otherValue;
        let message = valid ? '' : this.$t(`validation.same-as-invalid`, { attribute }).toString();
        return {
            valid,
            message
        };
    }
}
