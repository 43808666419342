import moment from "moment";

export type CurrencyFilterInterface = (price: any, decimal?: boolean, unit?: boolean) => any

export default {
    currency: (price: any, removeUnit = false) => {
        return Number(price).toLocaleString('id-ID', { style: 'currency', currency: 'IDR' }).slice(removeUnit?2:undefined)
    },
    dateformat: (date: string, format: string = "DD MMM YYYY") => {
        return moment(date).utc(true).format(format)
    },
    extracttext: (strElemet: string) => strElemet.HTMLtoString()
}
